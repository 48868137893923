<!--:totalRecords="totalRecords"
:lazy="true"
-->
<!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
<template>
  <div>
    <div class="responsive-container w-100">
      <div class="responsive-filter-label">Tên hạng mục chi phí</div>
      <InputText
          type="text"
          v-model="filters[$CommonList.getFieldFilter(responsiveFilter)]"
          class="text-box mb-3"
          placeholder="Nhập"
          @keyup="onFilter($event, responsiveFilter.filter.type, responsiveFilter.key)"
        />
    </div>
    <div
      class="responsive-container me-3 container-flex-end"
      v-if="actions.header"
    >
      <span class="responsive-action-label">Thêm mới</span>
      <Button
        type="button"
        :icon="getIcon(actions.header.icon)"
        :class="getClass(actions.header.name)"
        v-on:click="
          actionsClicked(actions.header.name, actions.header, slotProps)
        "
      >
      </Button>
    </div>
  <DataTable
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="scrollHeight !== '' ? true : false"
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate="
      $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM + ' {totalRecords}'
    "
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="c.class ? 'txt-' + c.class : ''"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="c.class ? 'txt-' + c.class : ''"
    >
      <template #body="slotProps">
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="c.style ? c.style : ''"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="c.style ? c.style : ''"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar
              v-if="c.custom == 'image'"
              :image="getData(c, slotProps.data)"
              size="xlarge"
            />
            <div
              v-if="c.custom == 'SelectionText'"
              v-html="callbackDataSelect(c, slotProps.data)"
            ></div>
          </div>
          <span
            v-else
            v-html="getData(c, slotProps.data)"
            :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"
          ></span>
        </div>
      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event, c.filter.type, c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event, c.filter.type, c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="
                  getCustomClass(
                    c,
                    slotProps.option[c.filter.optionKey],
                    slotProps.data
                  )
                "
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearRange"
          dateFormat="yy-mm-dd" 
          :placeholder="getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          />
          
          <Calendar
          v-if="c.filter.type == 'Date'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearRange"
          dateFormat="dd/mm/yy" 
          :placeholder="getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="single"
          appendTo="body"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="responsive-label">Hành động</span>
        {{ slotProps.data.color }}
        <div class="d-flex" style="justify-content: space-evenly;">
          <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
          ></Button>
        </div>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="
            actionsClicked(actions.header.name, actions.header, slotProps)
          "
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t("datatable.empty") }}
    </template>
    <template #loading>
      {{ $t("datatable.loading") }}
    </template>
  </DataTable>
</div>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
import AuthenticationApp from "@/core/AuthenticationApp";

export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  data() {
    return {
      //filters: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows:
        this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: "", // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService: null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction: null,
      firstRecordIndex: 0,
      yearRange : (new Date().getFullYear() - 100)+":"+(new Date().getFullYear() + 100),
      time_zone_local : moment.tz.guess(true),
      responsiveFilter: null,
    };
  },
  async created() {
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = localStorage.getItem(this.page_transaction)
          ? JSON.parse(localStorage.getItem(this.page_transaction))
          : {};
        this.firstRecordIndex = this.lazyParams.first;
        /* if (this.lazyParams){
                console.log("this.lazyParams.filters",this.lazyParams.filters);
      
              }*/
      }
    }
    this.loading = false;
    this.countDropdown++;
    this.onLazyEvent();
    this.getResponsiveFilter();
  },
  methods: {
    getResponsiveFilter(){
      var displayFields = this.model.getDisplayFields();
      var tempFilter = [];
      for (var i = 0; i < displayFields.length; i++) {
        if (displayFields[i].key == "name") {
          tempFilter.push(displayFields[i]);
        }
      }
      this.responsiveFilter = tempFilter[0];
      // console.log("SHIBABA", this.responsiveFilter);
      // console.log("SHIBABABA", this.responsiveFilter.filter.type);
    },
    callbackDataSelect(c, data) {
      var text = "";
      switch (c.key) {
        case "type":
          if (data.type == "1") {
            text = "Xe tải";
          } else if (data.type == "2") {
            text = "Xe máy";
          }
          break;
        case "classify":
          if (data.classify == "1") {
            text = "Nội bộ";
          } else if (data.classify == "2") {
            text = "Thuê ngoài";
          }
          break;
        case "company_code":
          text = data?.company?.code || "-";
          break;  
        case "unit_name":
          text = data.unit?.name || "-";
          break;
        case "active":
          if (data.active === true) {
            text = '<i class="pi pi-check icon-check"></i>';
          } else {
            text = '<i class="pi pi-times icon-times"></i>';
          }
          break;
        case "code":
          var services_code = data?.other_costs_services.map( m => { return m?.shipping_method?.code });
          text =  services_code.join('; ') || "-";
          break;
        case "name":
          var services_name = data?.other_costs_services.map( m => { return m?.shipping_method?.name });
          text =  services_name.join('; ') || "-";
          break;
        default:
      }
      return text;
    },
    select_date(event, col_name) {
      if (
        this.filters[col_name][0] !== null &&
        this.filters[col_name][1] !== null
      ) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name,
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if (col.inputOptions?.mode == "icon") {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange) f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image")
        style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      var value = "";
      if (col.type == "Date" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("DD/MM/yyyy HH:mm:ss");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "Hour" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (
          col.models !== undefined &&
          this.options[col.key] !== undefined
        ) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter((a) => a.value == row[col.key]);
          if (
            objResult !== undefined &&
            Object.keys(objResult) &&
            Object.entries(objResult).length
          ) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result =
            row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style)
        value = '<abbr style="' + col.style + '">' + value + "<abbr/>";
      else if (col.options) {
        var filtered = col.options.filter((a) => a.value === row[col.key]);
        if (
          Object.keys(filtered) &&
          Object.entries(filtered).length &&
          filtered[0].style
        ) {
          value =
            '<abbr style="' + filtered[0].style + '">' + value + "<abbr/>";
        }
      }
      return value;
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams: this.lazyParams,
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit";
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit";
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
      });
    },
    async dataList(lazyParams) {
      var offset = lazyParams.first;
      var limit = lazyParams.rows;
      var gqLQueryList = DataServices.getList(this.model.tableName);
      var where = {
        deleted: {_eq: false}
      };
      var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      where.company = { id: { _in: listDisplayCompany } };
    }
      var filters = lazyParams.filters;
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (!this.$commonFuction.isEmpty(value.value)) {
            if (value.type_input == "input") {
              if (key == "name") {
                where.other_costs_services = { shipping_method: { name: {
                      _iregex: this.$commonFuction.search(value.value),
                    }
                  }
                };
              } else if (key == "code") {
                where.other_costs_services = { shipping_method: { code: {
                      _iregex: this.$commonFuction.search(value.value),
                    }
                  }
                };
              } else {
                where[key] = { _ilike: "%" + value.value + "%" };
              }
            } else if (value.type_input == "select") {
              if (key == "company_code") {
                where.company = {
                  id: {_eq: value.value}
                };
              } else if (key == "active") {
                where["active"] = { _eq: value.value };
              } else if (key == "type_vehicle") {
                where["type_vehicle"] = { _eq: value.value };
              } else if (key == "classify") {
                where["classify"] = { _eq: value.value };
              } else if (key == "type_area_name") {
                where["type_area"] = { _eq: value.value };
              } else if (key == "unit_name") {
                where["unit_id"] = { _eq: value.value };
              }
            } else if (value.type_input == 'DateRange'){
              if(value.value !== undefined && value.value !== null){
                console.log("dsadasdas", value.value);
                where[key] = {'_gte': value.value.from,'_lte': value.value.to}
              }
            } else if (value.type_input == 'Date'){
              if(value.value !== undefined && value.value !== null){
              // console.log("dsadasdas");
                where[key] = {_eq: moment.tz(value.value, this.time_zone_local).format("yyyy-MM-DD")}
              }
            }
            
          }
        }
      }
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
          offset: offset,
          limit: limit,
          orderBy: { updated_at: "desc" },
        },
      });
      var listData = resData.data[this.model.tableName];

      var gqLQueryCount = DataServices.countData(this.model.tableName);
      var countRes = await this.$apollo.mutate({
        mutation: gqLQueryCount,
        variables: {
          where_key: where,
        },
      });
      var total =
        countRes.data[this.model.tableName + "_aggregate"]["aggregate"][
          "count"
        ];

      var dataRes = {
        listData: listData,
        total: total,
      };
      return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then((data) => {
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          if (
            data.listData[i].rel_object_group_uses !== undefined &&
            data.listData[i].rel_object_group_uses !== null
          ) {
            data.listData[i].name_group_filter =
              data.listData[i].rel_object_group_uses.group_id;
          } else {
            data.listData[i].name_group_filter = 0;
          }
          data.listData[i].company_code =  data.listData[i]?.company?.code || "-";
          var services_code = data.listData[i]?.other_costs_services.map( m => { return m?.shipping_method?.code });
          data.listData[i].code =  services_code.join(',') || "-";
          
          var services_name = data.listData[i]?.other_costs_services.map( m => { return m?.shipping_method?.name });
          data.listData[i].name =  services_name.join(',') || "-";
          
          data.listData[i].unit_name = data.listData[i]?.unit?.name || "-";
          data.listData[i].updated_by = data.listData[i]?.user_update?.fullname || "-";
        }
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event, type_input, key) {
      //this.loading = true;
      this.tableData = this.test;
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      // if(type_input == "Date"){
      //   if(this.filters[key] !== null && this.filters[key][0]== null){
      //     return false;
      //   }
      // }
    //   this.filtersCustom[key] = {
    //     type_input:type_input,
    //     value:this.filters[key],
    //   };
    //   console.log('this.filtersCustom:',this.filtersCustom);
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
      this.onLazyEvent();
      /*setTimeout(() => {
              this.customers = this.datasource.slice(event.first, event.first + event.rows);
              this.loading = false;
            }, 500);*/
    },
  },
};
</script>
<style lang="scss">
.p-paginator-bottom {
  padding: 0 !important;
}
.icon-check {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  color: #00973d;
}
.icon-times {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  color: #f5564e;
}
.container-flex-end{
  margin-bottom: 32px;
}
@media (max-width: 960px){
  .container-flex-end{
    display: flex !important; 
    justify-content: flex-end !important;
  }
}
.p-dropdown-label.p-inputtext.p-placeholder{
  padding-right: unset !important;
}
.p-dropdown{
  height: 45px;
  padding-top: 2px;
}
@media(min-width: 961px){
.txt-xs-col{
  /* max-width: 60px !important; */
  min-width: 60px !important;
  max-width: 60px !important;
}
.txt-small-col {
  max-width: 225px !important;
  min-width: 225px !important;
}

.txt-medium-col {
  max-width: 236px !important;
  min-width: 236px !important;
}

.txt-large-col {
  max-width: 300px !important;
  min-width: 300px !important;
}
}
</style>
