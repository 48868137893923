<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          v-on:action-click="onActionClicked"
        ></BaseDatatable>
        <Dialog
          header="Delete"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Are you sure you want to delete?
          <template
            #footer
          >
            <Button label="Delete" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Cancel"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from "@/core/DataServices";
import BaseDatatable from "./list";
// import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      filterOptions: {},
      options: [],
      listData: [],
      modelName: "",
      dataModel: null,
      title: "",
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: "edit",
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList: {
        where_key: {
          /*accounts: {
              role: {_eq: "admin"},
              deleted: {_eq: false}
            },*/
          deleted: { _eq: false },
        },
      },
      page_transaction: null,
      role: {
        view: false,
        add: false,
        edit: false,
      },
    };
  },
  mounted() {
    this.initParams();
  },
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    this.title = this.dataModel.name;
    this.$store.commit("setLoading", true);
    if (this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(
      this.$constants.KEY_MENU.LIST_OTHER_COSTS
    );
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0) {
      this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
    }
    this.role.add =
      arr_role.indexOf(this.$constants.ROLE.ADD) < 0 ? false : true;
    this.role.edit =
      arr_role.indexOf(this.$constants.ROLE.EDIT) < 0 ? false : true;
    this.role.delete =
      arr_role.indexOf(this.$constants.ROLE.DELETE) < 0 ? false : true;
  },
  watch: {
    $route: "initParams",
  },
  methods: {
    initParams() {
      this.$store.commit("setLoading", true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      this.callbackOptions();
    },
    onChange() {
      this.$store.commit("setLoading", true);
      this.$apollo.queries.listData.refresh();
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name);
      var result = [];
      if (field.type == "Selection") {
        //result = field.options;
        if (field.options) result = field.options;
        else if (field.models && this.options[name])
          result = this.options[name];
      } else {
        result =
          this.filterOptions[name] != null ? [...this.filterOptions[name]] : [];
      }
      return result;
    },
    getRowActions() {
      var actions = {};
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
         if (res){
           actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
         }
        })*/
      if (this.role.add) {
        actions.header = {
          name: "add",
          icon: "plus",
          path: `/admin/list-other_costs/add`,
        };
      }
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {};
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
          buttons.edit = {
            name: "edit",
            icon: "pencil",
            key: "id",
            path: `/admin/list-other_costs/edit/`,
          };
        }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        if (that.role.delete) {
          buttons.delete = { name: "delete", icon: "trash", key: "id" };
        }

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData);
          Object.assign(buttons, modelaction);
        }
        return buttons;
      };
      return actions;
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      for (let index = 0; index < fields.length; index++) {
        var attributes = [];
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //var where_status = {}
          /*if( element['name']== "rel_status"){
              where_status =  {
                type: {'_eq': 'ORDER'}
              }
            }*/
          var where_ = {};
          if (element["name"] == "name_group_filter") {
            where_ = {
              active: { _eq: true },
              deleted: { _eq: false },
            };
            element.models.tableName = "group";
          }
          if (element.where_option){
            where_ = element.where_option
          }
          var { data } = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {
              fields: attributes.join(","),
            }),
            variables: { where_key: where_ },
          });
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({
                  value: ele[attributes[0]],
                  label: ele[attributes[1]],
                });
              }
            }
          }
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    async onActionClicked(payload) {
      var action = payload.action;
      var data = null;
      if (payload.data) {
        data = payload.data;
      }
      var pageTransaction = new Date().getTime();
      switch (action.name) {
        case "view":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] });
          } else {
            this.editId = data.data[action.key];
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case "edit":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            localStorage.setItem(
              pageTransaction.toString(),
              JSON.stringify(payload.lazyParams)
            );
            this.$router.push({
              path: action.path + data.data[action.key],
              query: { page_transaction: pageTransaction.toString() },
            });
          } else {
            this.editId = data.data[action.key];
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case "add":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path });
          } else {
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case "custom":
          await action.handler(data.data, this);
          this.componentKey++;
          break;
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      let variables = {
        'data_update': {deleted: true},
        'where_key': {
          'id': {'_eq': this.deleteId}
        }
      }
      await this.$apollo.mutate({
            mutation: DataServices.updateData('other_costs'),
            variables: variables
        }).then(async (res) => {
            console.log(res);
            var router_back = {path: '/admin/list-other_costs'};
            if (!this.$commonFuction.isEmpty(router_back)){
              console.log("this.page_transaction:",this.page_transaction);
              router_back.query = { page_transaction: this.page_transaction.toString() };
            }
            this.$router.push(router_back);
            this.$commonFuction.is_loading(false);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successfully', life: 3000 })
            //this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
            // window.location.reload()
            this.componentKey++;
        });
    }
  },
};
</script>
<style lang="css"></style>
